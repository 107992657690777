import React, {Component} from "react";
import Header from "../Landing/Header";
import axios from "axios";
import LoginModal from "../Modals/LoginModal";
import {
    MdClose,
    MdFilterList,
    MdHighlightOff,
    MdKeyboardArrowDown,
} from "react-icons/md";

import buildAPIUrl from "../../../Services/UrlBuilder";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import cards from "../../../assets/Group 23.png";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";
import ResourcesNav from "./ResourcesNav";
import MetaTags from "react-meta-tags";
import NotFound from "./NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import mixpanel from "mixpanel-browser";
import mixPanelApi from "../../../Services/ApiService";
import SeoMetaData from "./SeoMetaData";
import ReactPaginate from "react-paginate";
class Communities extends Component{
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    state = {
        items:[],
        token: localStorage.getItem('token'),
        filters:{
            Slack:false,
            Linkedin:false,
            Facebook:false
        },
        tags:"",
        filter:"",
        slackchecked:false,
        facebookchecked:false,
        linkedinchecked:false,
        filteritems:[],
        isMount: false,
        showfilter:false,
        loading:true,
        logintype:"",
        platforms:[],
        startTime:new Date * 1,
        pages:0,
        itemscount:15,
        page:0,
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);

    };
    async componentDidMount() {
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        if (window.location.hash.includes("#")){
            this.setState({
                filter:window.location.hash.replace("#", "").replaceAll("%20", " ")
            })
        } else {
            window.scrollTo(0,0)
        }
        await EventsHandler("Communities Section Opened",  document.URL)
        await window.addEventListener("beforeunload", (event) => {
            event.preventDefault()
            let endTime = new Date() * 1;
            let elapsed = endTime - this.state.startTime;
            EventsHandler("Communities page closed",  document.URL, (elapsed/1000/60).toFixed(2))
        });
        document.addEventListener("mousedown", this.handleClickOutside);
        this.setState({
            isMount:true
        })

      axios.get(buildAPIUrl('v1/research_communities/')).then((res)=>{
            this.setState({
                items: res.data[0],
                loading: false,
                platforms: res.data[1].platforms,
                pages:Math.floor(res.data[0].length / this.state.itemscount),
            })
        }).catch((err)=>{

        })
        //Increase views by 1
        axios.get(buildAPIUrl('v1/collections/2/')).then(()=>{

        }).catch((error)=>{

        })
    }
    handleFilter = (e)=>{
        const target = e.target;
        var value = target.name;
        if(target.checked){
            this.state.items[value] = value;
            console.log(this.state.items[value])
        }else{
            this.state.items.splice(value, 1);
        }
        const response1 = axios.get(buildAPIUrl('v1/research_communities/?platform="Facebook"')).then((res)=>{
            console.log(res)
            this.setState({
                items: res.data
            })
        }).catch((err)=>{

        })
    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleSearchVoc = (e) => {
        localStorage.setItem('searchquery', this.state.filter)
        window.location.assign('/all-results')
    }
    onClearFilters = async (event) =>{
       this.setState({
          tags:""
       })

        const response1 =  await  axios.get(buildAPIUrl(`v1/research_communities/?platform=${this.state.tags}`)).then((res)=>{
            console.log(res)
            this.setState({
                items: res.data
            })
        }).catch((err)=>{

        })
    }
    onChangeValue = async (event) => {
        if(this.state.token) {
            const isChecked = event.target.checked
            if(isChecked){
                await this.setState({filteritems: [...this.state.filteritems, event.target.value]})
            } else {
                const index = this.state.filteritems.indexOf(event.target.value);
                this.state.filteritems.splice(index,1);
                await this.setState({filteritems: this.state.filteritems})
            }

            if(this.state.filteritems.length > 0) {
                const response1 =  await  axios.get(buildAPIUrl(`v1/research_communities/?platform=${this.state.filteritems}`)).then((res)=>{
                    console.log(res.data)
                    if(res.data.length ===1) {
                        this.setState({
                            items: res.data[0]
                        })
                    } else if (res.data.length ===2) {
                        this.setState({
                            items: [...res.data[0], ...res.data[1]]
                        })
                    } else if (res.data.length ===3) {
                        this.setState({
                            items: [...res.data[0], ...res.data[1], ...res.data[2]]
                        })
                    }
                }).catch((err)=>{

                })
            } else {
                const response = await axios.get(buildAPIUrl('v1/research_communities/')).then((res)=>{
                    this.setState({
                        items: res.data[0],
                        loading: false,
                        platforms: res.data[1].platforms
                    })
                }).catch((err)=>{

                })
            }
        } else {
            this.setState({
                filteritems:[]
            })
           await this.handleModalOpen("2")
        }

    }
    handleModalClose = ()=>{
        this.setState({
            show: false,
            logintype:""
        })
        window.location.reload()
    }
    handleModalOpen = async (logtype)=>{
        await this.setState({
            show: true,
            logintype:logtype
        })
    }
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    handleClickOutside = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({
                showfilter:false
            })
        }

    }
    saveEvent = (id)=> {
        EventsHandler("Communities Link Clicked", document.URL, "none", id)
        axios.get(buildAPIUrl(`v1/research_communities/${id}/`)).then(()=>{

        }).catch((error)=>{

        })
    }
    handlePageClick =  async (event) => {
        console.log(event.selected)
        await window.scrollTo(0,0)
        let page = event.selected;
        await this.setState({
            page:page
        })

    }
    handleClearAll = async ()=>{
        this.setState({ filteritems: [] });
      await  axios.get(buildAPIUrl('v1/research_communities/')).then((res)=>{
            this.setState({
                items: res.data[0],
                loading: false,
                platforms: res.data[1].platforms
            })
          const checkboxes = document.querySelectorAll('input[type="checkbox"]');
          checkboxes.forEach(checkbox => checkbox.checked = false);
        }).catch((err)=>{

        })
    }
    render() {
        const filtereditems = this.state.items.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })

        return(
            <>

               {/* <MetaTags>
                    <title>UX Research Communities - Research Bookmark</title>
                    <meta name="description" content="Highly recommended list of online UX communities you can join and connect with fellow Researchers" />
                    <meta property="og:title" content="UX Research Communities- Research Bookmark" />
                    <meta property="og:url" content="https://www.researchbookmark.io/uxr-communities" />
                </MetaTags>*/}
                <SeoMetaData title={"Communities"} />
                <LoginModal openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show} type={"2"}/>
                <MediaQuery minWidth={1200} >
                    <Header pageType={"Free"} />
                    <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"7rem"}}>
                        <div className="col-md-2 side-filters">

                        </div>
                        <div className="col-md-8 col-12 px-4">
                            <div className="search-section col-12 col-md-12 mt-4 ">
                                <div className="row d-flex align-items-center ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input input-group border"  style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for communities"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources">
                                <div className="row">
                                    <ResourcesNav />
                                    <div className={this.state.filteritems.length > 0 ? "col-md-12 mt-3 d-flex justify-content-end" : "d-none"}>
                                        {
                                            this.state.filteritems.length > 0 ?
                                                <>
                                                    <ul className={"p-0"} style={{listStyleType:"none"}}>
                                                        {
                                                            this.state.filteritems.map((filter)=>(
                                                                <li className={"d-flex justify-content-between align-items-center"} style={{color:"#767676", borderRadius:"8px", padding:"3px 10px", float: "left", marginRight:"10px", background:"#E8E8E8",height:"2rem"}}>
                                                                    <h6 className={"p-0 m-0"} style={{float:"left", marginTop:"6px", fontSize:"14px", fontWeight:"500", color:"#202020"}}>{filter}</h6> <span onClick={()=>this.handleRemoveFilterItem(filter)} style={{float:"left", marginLeft:"10px"}}> <MdClose /></span>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                    <button onClick={this.handleClearAll} className={"clear"} style={{height:"2rem", border:"none", backgroundColor:"#202020", color:"white", fontSize:"14px", borderRadius:"8px", padding:"3px 10px", }}>Clear All</button>

                                                </> :
                                                <></>
                                        }
                                    </div>
                                    <div className="col-md-12 col-6 border p-0 rounded-3" style={{marginTop:"1rem"}}>
                                        <div className={"container py-4 d-flex align-items-center"}>
                                            <div className={"row"}>
                                                <div className={"col-md-8"}>
                                                    <h1 style={{fontSize:"18px", marginLeft:"0", lineHeight:"28px"}}>Best UX Communities on Slack, LinkedIn, Meetup, Facebook and Reddit for Networking & Growth</h1>
                                                </div>
                                                <div className={"col-md-4 d-flex justify-content-end"}>
                                                    <a  className="" target="_blank" style={{color:"black", fontSize:"14px", width: "100%", textDecoration:"none", textAlign:"right"}}>
                                                        <div className="dropdown-toggle originals-toggle" id="navbarDropdown"
                                                             role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                            <h6 style={{marginLeft:"0"}}>
                                                        <span>
                                                            <MdFilterList className={"ori-icon"} />   Platforms
                                                        </span>

                                                            </h6>
                                                        </div>
                                                        <div className="dropdown-menu originals-category-filter" aria-labelledby="navbarDropdown" >
                                                            <div className="container-fluid" style={{borderRadius:"4px"}} >
                                                                {
                                                                    this.state.platforms ?
                                                                        <>
                                                                            {Object.values(this.state.platforms).map((platform)=>(
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox"
                                                                                           id="flexRadioDefault1" value= {platform} onClick={this.onChangeValue} />
                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                                        {platform}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>


                                        </div>
                                        {
                                            this.state.loading ? <>
                                                    {
                                                        [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                    }

                                                </> :
                                                <table className=" history-table" style={{overflow: "hidden"}}>
                                                    <thead>
                                                    <tr>
                                                        <th width={{width: "30%"}}>Community name</th>
                                                        <th width={{width: "30%"}}>Platform</th>
                                                        <th>Audience</th>
                                                        <th>Link</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        filtereditems.length > 0 ?
                                                            filtereditems.sort((a, b) => b.id - a.id).slice(this.state.page * this.state.itemscount, (this.state.page + 1) * this.state.itemscount).map((item) => (
                                                                <tr id={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.platform}</td>
                                                                    <td style={{padding:".8rem 0"}}>{item.audience}</td>
                                                                    <td><a onClick={()=>this.saveEvent(item.name, item.url)} href={item.url} target="_blank" style={{
                                                                        color: "#522FFF",
                                                                        textDecoration: "underline",
                                                                        width: "35%"
                                                                    }}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a>
                                                                    </td>
                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                {
                                                                    this.state.isMount ?
                                                                        <td colSpan="4" className="search-message">
                                                                            <img
                                                                                src={cards}/><br/>
                                                                            <h6>No results found for this keyword</h6>
                                                                            <p>We could not find any community related to your search.
                                                                                <br/>Browse more or contribute communities to the platform</p>
                                                                            <NotFound term={this.state.filter} />
                                                                        </td> : <td colSpan="3" className="search-message"><img
                                                                            src={cards}/><br/>
                                                                            <h6>Loading.....</h6>
                                                                        </td>
                                                                }

                                                            </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                        }
                                        {
                                            filtereditems.length > 0 ?
                                                <div className={"container d-flex justify-content-center"}>
                                                    <ReactPaginate
                                                        pageCount={this.state.pages}
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={'pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                                : ""
                                        }
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>

                        <div className="row p-0 mobile position-relative">
                            <div>
                                <div ref={this.ref} className={this.state.showfilter? "row  p-0 d-flex justify-content-center position-absolute" : " d-none mobfilter1"} style={{zIndex:"1000", height:"80vh", width:"100%"}} >
                                    <div className=" bg-white border-top px-4" >
                                        <div className={"row mt-3"} style={{paddingLeft:".5rem"}}>
                                            <div className={"col-4"}>
                                                <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                            </div>
                                            <div className={"col-4"}>
                                                <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                            </div>
                                            <div className={"col-4 d-flex justify-content-end"} style={{cursor:"pointer"}}>
                                                <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                            </div>
                                        </div>
                                        {
                                            this.state.platforms ?
                                                <>
                                                    {Object.values(this.state.platforms).map((platform)=>(
                                                        <div className="form-check mt-2 d-flex align-items-center">
                                                            <input className="form-check-input" type="checkbox"
                                                                   id="flexRadioDefault1" value= {platform} onClick={this.onChangeValue} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                {platform}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </>
                                                : ""
                                        }
                                    </div>
                                    <button onClick={this.handleFilterShow} className={"btn btn-save-filter"}>
                                        Save Filter
                                    </button>
                                </div>
                                <Header pageType={"Free"} />
                                <div className="col-md-8 col-12">
                                    <div className="search-section col-12 col-md-12 mb-2 mt-4 ">
                                        <div className="row d-flex align-items-center  ">
                                            <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                    <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                        <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                        <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                               style={{width: "100%", border:"none"}} placeholder="Search for communities"/>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="saved-sources"  >
                                            <ResourcesNav />
                                        <div className={"row p-0 m-0"}>
                                            <div className="col-md-8 col-10 d-flex align-items-center mt-2 p-0" >
                                                <h1 style={{fontWeight:"600"}}>Best UX Communities on Slack, LinkedIn, Meetup, Facebook and Reddit for Networking & Growth</h1>
                                            </div>
                                            <div className="col-md-4 mt-2 col-2 d-flex justify-content-end">
                                                <a className="mobfilter1 d-flex justify-content-end" style={{color:"#757D8A", fontSize:"10px"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter </h6></a>
                                            </div>
                                        </div>
                                        {
                                            this.state.loading ? <>
                                                    {
                                                        [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                    }

                                                </> :
                                                <table className=" history-table mt-3" style={{overflow: "hidden"}}>
                                                    <thead>
                                                    <tr>
                                                        <th>Community name</th>
                                                        <th style={{paddingRight:"1.5rem"}}>Link</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody style={{paddingLeft:"2.7rem", paddingRight:"1.7rem"}}>
                                                    {
                                                        filtereditems.length > 0 ?
                                                            filtereditems.sort((a, b) => b.id - a.id).map((item) => (
                                                                <tr >
                                                                    <td >{item.name}</td>
                                                                    <td style={{paddingRight:"1.5rem"}}><a onClick={()=>this.saveEvent(item.name, item.url)} href={item.url} target="_blank" style={{
                                                                        color: "#00944D",
                                                                        textDecoration: "underline",
                                                                        width: "35%"
                                                                    }}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a>
                                                                    </td>
                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                {
                                                                    this.state.isMount ?
                                                                        <td colSpan="2" className="search-message">
                                                                            <img
                                                                                src={cards}/><br/>
                                                                            <h6>No results found for this keyword</h6>
                                                                            <p>We could not find any community related to your search.
                                                                                <br/>Browse more or contribute communities to the platform</p>
                                                                            <NotFound term={this.state.filter} />
                                                                        </td> : <td colSpan="3" className="search-message"><img
                                                                            src={cards}/><br/>
                                                                            <h6>Loading.....</h6>
                                                                        </td>
                                                                }

                                                            </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                        }
                                    </div>

                                </div>
                            </div>

                        </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <Header pageType={"Free"} />
                    <div className="row mobile p-0 position-relative" style={{paddingLeft: "1rem", paddingRight:"1rem"}}>
                        <div className={"container"}>
                            <div ref={this.ref} className={this.state.showfilter? "row  p-0 d-flex justify-content-center position-absolute" : " d-none mobfilter1"} style={{zIndex:"1000", height:"80vh", width:"100%"}} >
                                <div className=" bg-white border-top px-4" >
                                    <div className={"row mt-3"} style={{paddingLeft:".5rem"}}>
                                        <div className={"col-4"}>
                                            <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                        </div>
                                        <div className={"col-4"}>
                                            <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                        </div>
                                        <div className={"col-4 d-flex justify-content-end"} style={{cursor:"pointer"}}>
                                            <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                        </div>
                                    </div>
                                    {
                                        this.state.platforms ?
                                            <>
                                                {Object.values(this.state.platforms).map((platform)=>(
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               id="flexRadioDefault1" value= {platform} onClick={this.onChangeValue} />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                            {platform}
                                                        </label>
                                                    </div>
                                                ))}
                                            </>
                                            : ""
                                    }
                                </div>
                                <button onClick={this.handleFilterShow} className={"btn btn-save-filter"}>
                                    Save Filter
                                </button>
                            </div>
                            <div className="col-md-8 col-12">
                                <div className="search-section col-12 col-md-12 mb-2 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-11 col-md-12 d-flex justify-content-center" style={{paddingLeft:"2.5rem"}}>
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                    <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                    <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "100%", border:"none"}} placeholder="Search for communities"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <div className="saved-sources"  >
                                    <div className="row" style={{paddingLeft:"1.9rem", paddingRight:"1.7rem"}}>
                                        <ResourcesNav />
                                    </div>
                                    <div className={"row"} style={{paddingLeft:"1.9rem", paddingRight:"1.7rem"}}>
                                        <div className="col-md-8 col-10 d-flex align-items-center mt-2" >
                                            <h1>Best UX Communities on Slack, LinkedIn, Meetup, Facebook and Reddit for Networking & Growth</h1>
                                        </div>
                                        <div className="col-md-4 mt-2 col-2 d-flex justify-content-end">
                                            <a className="mobfilter1 d-flex justify-content-end" style={{color:"#757D8A", fontSize:"10px"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter </h6></a>
                                        </div>
                                    </div>
                                    {
                                        this.state.loading ? <>
                                                {
                                                    [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                }

                                            </> :
                                            <table className=" history-table mt-3" style={{overflow: "hidden"}}>
                                                <thead>
                                                <tr>
                                                    <th style={{paddingLeft:"2.7rem"}}>Community name</th>
                                                    <th style={{paddingLeft:"2.7rem", paddingRight:"1.5rem"}}>Link</th>

                                                </tr>
                                                </thead>
                                                <tbody style={{paddingLeft:"2.7rem", paddingRight:"1.7rem"}}>
                                                {
                                                    filtereditems.length > 0 ?
                                                        filtereditems.sort((a, b) => b.id - a.id).map((item) => (
                                                            <tr >
                                                                <td style={{paddingLeft:"2.7rem"}}>{item.name}</td>
                                                                <td style={{paddingLeft:"2.7rem", paddingRight:"1.5rem"}}><a onClick={()=>this.saveEvent(item.name, item.url)} href={item.url} target="_blank" style={{
                                                                    color: "#00944D",
                                                                    textDecoration: "underline",
                                                                    width: "35%"
                                                                }}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a>
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr>
                                                            {
                                                                this.state.isMount ?
                                                                    <td colSpan="2" className="search-message">
                                                                        <img
                                                                            src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>We could not find any community related to your search.
                                                                            <br/>Browse more or contribute communities to the platform</p>
                                                                        <NotFound term={this.state.filter} />
                                                                    </td> : <td colSpan="3" className="search-message"><img
                                                                        src={cards}/><br/>
                                                                        <h6>Loading.....</h6>
                                                                    </td>
                                                            }

                                                        </tr>
                                                }
                                                </tbody>
                                            </table>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </MediaQuery>

                <Footer/>
            </>
        )
    }
}
export default Communities