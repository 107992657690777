import React, {Component} from "react";
import Header from "../Landing/Header";
import axios from "axios";
import LoginModal from "../Modals/LoginModal";
import Accordion from 'react-bootstrap/Accordion';
import {
    MdClose,
    MdHighlightOff,
    MdKeyboardArrowDown,
} from "react-icons/md";
import buildAPIUrl from "../../../Services/UrlBuilder";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import cards from "../../../assets/Group 23.png";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";
import ResourcesNav from "./ResourcesNav";
import MetaTags from 'react-meta-tags';
import NotFound from "./NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "./SeoMetaData";
import ReactPaginate from "react-paginate";


class Conferences extends Component{
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    state = {
        items: [],
        token: localStorage.getItem('token'),
        costitems: [],
        free:"",
        paid:"",
        upcoming:"",
        past:"",
        virtual:"",
        onsite:"",
        filter:"",
        tags:"",
        locationtags:"",
        mobilelocationtags:"",
        locationtag: "",
        tag:"",
        mobiletags:"",
        mobiletag:"",
        showfilter:false,
        loading:true,
        pages:0,
        page:0,
        itemscount:15,

    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };
     componentDidMount () {
         if(!localStorage.getItem('token')){
             window.location.assign("/")
         }
         EventsHandler("Conferences Section Opened",  document.URL)
        window.scrollTo(0,0)
        document.addEventListener("mousedown", this.handleClickOutside);
        axios.get(buildAPIUrl(`v1/conferences/?location=${this.state.locationtags}&cost=${this.state.tag}`)).then((res)=>{
          this.setState({
                items: res.data,
                loading: false,
              pages:Math.floor(res.data.length / this.state.itemscount),
            })
            console.log(res.data)
        }).catch((err)=>{

        })
         //Increase views by 1
         axios.get(buildAPIUrl('v1/collections/4/')).then(()=>{

         }).catch((error)=>{

         })
    }
    months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    handleClickOutside = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({
                showfilter:false,

            })
        }

    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleSearchVoc = (e) => {
        localStorage.setItem('searchquery', this.state.filter)
        window.location.assign('/all-results')
    }
    onChangeValue = async (event) => {
        if(this.state.token) {
            console.log(event.target.value);
            await this.setState({
                tags:event.target.value,
            })
            const response1 =  await  axios.get(buildAPIUrl(`v1/conferences/?location=${this.state.locationtags}&cost=${this.state.tags}`)).then((res)=>{
                this.setState({
                    items: res.data
                })
            }).catch((err)=>{

            })
        } else {
            await this.handleModalOpen()
        }

    }
    resetFilter = () =>{
        window.location.reload()
    }
    onChangeMobileValue = async (event) => {
        if(this.state.token) {
            await this.setState({
                mobiletags:event.target.value,
            })
            const response1 =  await  axios.get(buildAPIUrl(`v1/conferences/?location=${this.state.mobilelocationtags}&cost=${this.state.mobiletags}`)).then((res)=>{
                this.setState({
                    items: res.data
                })
            }).catch((err)=>{

            })
        } else {
            await this.handleModalOpen()
        }

    }
    handleModalClose = ()=>{
        this.setState({
            show: false
        })

    }
    handleModalOpen = async ()=>{
        console.log("modal open")
        await this.setState({
            show: true,
        })
    }
    onChangeValueLocation = async (event) => {
        if(this.state.token) {
            await this.setState({
                locationtags:event.target.value,
            })
            const response1 =  await  axios.get(buildAPIUrl(`v1/conferences/?location=${this.state.locationtags}&cost=${this.state.tags}`)).then((res)=>{
                this.setState({
                    items: res.data
                })
            }).catch((err)=>{

            })
        } else  {
            await this.handleModalOpen()
        }

    }
    onChangeMobileValueLocation = async (event) => {
        if(this.state.token) {
            await this.setState({
                mobilelocationtags:event.target.value,
            })
            const response1 =  await  axios.get(buildAPIUrl(`v1/conferences/?location=${this.state.mobilelocationtags}&cost=${this.state.mobiletags}`)).then((res)=>{
                this.setState({
                    items: res.data
                })
            }).catch((err)=>{

            })
        } else  {
            await this.handleModalOpen()
        }

    }
    onClearFilters = async (event) =>{
        this.setState({
            tags:""
        })
        window.location.reload()
    }
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    handleClickCount =(id)=>{
            EventsHandler("Conferences Link Clicked", document.URL, "none", id)
        //Increase views by 1
        axios.get(buildAPIUrl(`v1/conferences/${id}/`)).then(()=>{

        }).catch((error)=>{

        })
    }
    handlePageClick =  async (event) => {
        console.log(event.selected)
        await window.scrollTo(0,0)
        let page = event.selected;
        await this.setState({
            page:page
        })

    }
    clearFilters = async () => {
        this.setState({
            tags:"",
            locationtags:""
        })
        await axios.get(buildAPIUrl(`v1/conferences/?location=${this.state.locationtags}&cost=${this.state.tag}`)).then((res)=>{
            this.setState({
                items: res.data,
                loading: false
            })
        }).catch((err)=>{

        })
    }
    handleClearAll = async ()=>{
        this.setState({
            mobiletags:'',
            mobilelocationtags:''
        })
      await  axios.get(buildAPIUrl(`v1/conferences/?location=${this.state.locationtags}&cost=${this.state.tag}`)).then((res)=>{
            this.setState({
                items: res.data,
                loading: false
            })
        }).catch((err)=>{

        })
        const checkboxes = document.querySelectorAll('input[type="radio"]');
        checkboxes.forEach(checkbox => checkbox.checked = false);
    }
    render() {
        const filtereditems = this.state.items.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        return(
            <>
               {/* <MetaTags>
                    <title>UX Research Conferences - Research Bookmark</title>
                    <meta name="description" content="Looking to attend a conference? Explore our list of over 60 constantly updated ux research conferences that are a great fit for up skilling and networking." />
                    <meta property="og:title" content="UX Research Conferences - Research Bookmark" />
                    <meta property="og:url" content="https://www.researchbookmark.io/research-vocabulary" />
                </MetaTags>*/}
                <SeoMetaData title={"Conferences"} />

                <LoginModal type={"2"} openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                <MediaQuery minWidth={1200} >
                    <Header pageType={"Free"} />
                    <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"7rem"}}>
                        <div className="col-md-2 side-filters">

                        </div>
                        <div className="col-md-8 col-12 px-4">
                            <div className="search-section col-12 col-md-12 mt-4 ">
                                <div className="row d-flex align-items-center ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for conferences"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources">
                                <div className="row" >
                                    <ResourcesNav />
                                    <div className={this.state.mobiletags || this.state.mobilelocationtags ? "col-md-12 mt-3 d-flex justify-content-end" : "d-none"}>
                                        <ul className={"p-0"} style={{listStyleType:"none"}}>
                                            <li className={this.state.mobiletags ? "d-flex justify-content-between align-items-center":"d-none"} style={{color:"#767676", borderRadius:"8px", padding:"3px 10px", float: "left", marginRight:"10px", background:"#E8E8E8",height:"2rem"}}>
                                                <h6 className={"p-0 m-0 text-capitalize"} style={{float:"left", marginTop:"6px", fontSize:"14px", fontWeight:"500", color:"#202020"}}>{this.state.mobiletags}</h6> <span style={{float:"left", marginLeft:"10px"}}> <MdClose /></span>
                                            </li>
                                            <li className={this.state.mobilelocationtags ? "d-flex justify-content-between align-items-center" : "d-none"} style={{color:"#767676", borderRadius:"8px", padding:"3px 10px", float: "left", marginRight:"10px", background:"#E8E8E8",height:"2rem"}}>
                                                <h6 className={"p-0 m-0 text-capitalize"} style={{float:"left", marginTop:"6px", fontSize:"14px", fontWeight:"500", color:"#202020"}}>{this.state.mobilelocationtags}</h6> <span style={{float:"left", marginLeft:"10px"}}> <MdClose /></span>
                                            </li>
                                            <button onClick={this.handleClearAll} className={"clear"} style={{height:"2rem", border:"none", backgroundColor:"#202020", color:"white", fontSize:"14px", borderRadius:"8px", padding:"3px 10px", }}>Clear All</button>
                                        </ul>
                                    </div>
                                    <div className="col-md-12 col-6 border p-0 rounded-3" style={{marginTop:"1rem"}}>
                                        <div className={"container py-4"}>
                                            <div className={"row"}>
                                                <div className={"col-md-8 d-flex align-items-center"}>
                                                    <h1 style={{fontSize:"18px", marginLeft:"0"}}>Top UX Conferences to Attend in 2023</h1>
                                                </div>
                                                <div className={"col-md-4 d-flex align-items-center"}>
                                                    <a className="" target="_blank" style={{color:"black", fontSize:"14px", width: "100%", textDecoration:"none", textAlign:"right"}}>
                                                        <div className="dropdown-toggle originals-toggle" id="navbarDropdown"
                                                             role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                            <h6 style={{margin:"0"}}>
                                                        <span>
                                                              Filters <MdKeyboardArrowDown />
                                                        </span>

                                                            </h6>
                                                        </div>
                                                        <div  onClick={(e) => e.stopPropagation()} className="dropdown-menu originals-category-filter" aria-labelledby="navbarDropdown" style={{ overflow:"scroll"}}>
                                                            <div className="container-fluid ">
                                                                <Accordion>
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>Sort by Cost</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div className="" onChange={this.onChangeMobileValue}>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="mobilecost"
                                                                                           id="flexRadioDefault1"  value="free" checked={this.state.mobiletags==="free"}  />
                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                        Free
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="mobilecost"
                                                                                           id="flexRadioDefault1" value="paid" checked={this.state.mobiletags==="paid"} />
                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                        Paid
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                    <Accordion.Item eventKey="1">
                                                                        <Accordion.Header>Sort by Location</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div className="" onChange={this.onChangeMobileValueLocation}>
                                                                                <div className="form-check" >
                                                                                    <input className="form-check-input" type="radio" name="location"
                                                                                           id="flexRadioDefault1" value="virtual" checked={this.state.mobilelocationtags === "virtual"} />
                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                        Virtual
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="location"
                                                                                           id="flexRadioDefault1" value="onsite" checked={this.state.mobilelocationtags === "onsite"} />
                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                        On-site
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="location"
                                                                                           id="flexRadioDefault1" value="onsite and virtual" checked={this.state.mobilelocationtags === "onsite and virtual"} />
                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                        Both
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                                <h6 onClick={this.resetFilter} style={{color:"green", cursor:"pointer"}}>Reset</h6>

                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>


                                        </div>
                                        {
                                            this.state.loading ? <>
                                                    {
                                                        [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                    }

                                                </> :
                                                <table className="history-table table-responsive" style={{overflow: "hidden"}}>
                                                    <thead>
                                                    <tr>
                                                        <th  style={{overflow: "hidden", zIndex:"1"}}>Conference name</th>
                                                        <th style={{overflow: "hidden"}}>Date</th>
                                                        <th >Event type/location</th>
                                                        <th className=" hidden-mob">Cost</th>
                                                        <th>Link</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody className="mobmodal">
                                                    {
                                                        filtereditems.length > 0 ?
                                                            filtereditems.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)).slice(this.state.page * this.state.itemscount, (this.state.page + 1) * this.state.itemscount).map((item) => (
                                                                <tr className={new Date(item.start_date).toLocaleDateString() - new Date().toLocaleDateString() < "0" ? "passed" : ""} >
                                                                    <td style={{overflow: "hidden"}}>{item.name} </td>
                                                                    <td style={{overflow: "hidden"}}>
                                                                        {
                                                                            item.start_date===null ? "" :
                                                                                this.months[new Date(item.start_date).getMonth()] + " " + new Date(item.start_date).getDate() + " " +  new Date(item.start_date).getFullYear() } - {this.months[new Date(item.end_date).getMonth()] + " " + new Date(item.end_date).getDate() + " " + new Date(item.start_date).getFullYear()}
                                                                    </td>
                                                                    <td className={"text-capitalize"}><div  className={"history-tag"}>{item.location}</div></td>
                                                                    {/*<td>{item.cost}</td>*/}
                                                                    <td><a href={item.url}
                                                               style={{color: "#522FF", textDecoration: "underline"}}>
                                                            {
                                                                item.url ? item.url.replace("https://", "").replace("www.", "").replace("http://", "") : ""}</a>
                                                        </td>
                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                <td colSpan="5" className="search-message">
                                                                    <img src={cards}/><br/>
                                                                    <h6>No results found for this keyword</h6>
                                                                    <p>This word is not available in conferences, try our search
                                                                        engine instead</p>
                                                                    <NotFound term={this.state.filter} />
                                                                </td>
                                                            </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                        }
                                        {
                                            filtereditems.length > 0 ?
                                                <div className={"container d-flex justify-content-center"}>
                                                    <ReactPaginate
                                                        pageCount={this.state.pages}
                                                        previousLabel={'Previous'}
                                                        nextLabel={'Next'}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={'pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="container-fluid p-0 mobile position-relative">
                        <div ref={this.ref} className={this.state.showfilter? "row  p-0 d-flex justify-content-center position-absolute" : " d-none mobfilter1"} style={{zIndex:"1000", height:"80vh", width:"100%", margin:"0"}} >
                            <div className=" bg-white border-top px-4" >
                                <div className={"row mt-3"} style={{paddingLeft:".9rem", paddingRight:"2rem"}}>
                                    <div className={"col-4"}>
                                        <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                    </div>
                                    <div className={"col-4"}>
                                        <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                    </div>
                                    <div className={"col-4 d-flex justify-content-end"} style={{cursor:"pointer"}}>
                                        <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                    </div>
                                </div>
                                <h6 className={"filter-title1 text-center"} style={{marginTop:"1rem"}}>Sort by</h6>
                                <hr style={{marginLeft:"1rem", marginRight:"2rem"}}/>
                                <h6 className={"filter-title1"} style={{marginTop:"1rem", paddingLeft:"1rem"}}>Date</h6>
                                <div className="" onChange={this.onChangeValue} style={{paddingLeft:"1rem"}}>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio" name="cost"
                                               id="flexRadioDefault1"  value="free" checked={this.state.tags==="free"}  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            Free
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio" name="cost"
                                               id="flexRadioDefault1" value="paid" checked={this.state.tags==="paid"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            Paid
                                        </label>
                                    </div>
                                </div>
                                <hr style={{marginLeft:"1rem", marginRight:"2rem", marginTop:"1.2rem", height:".1rem"}}/>
                                <h6 className={"filter-title1"} style={{marginTop:"1rem", paddingLeft:"1rem"}}>Cost</h6>

                                <div className="" onChange={this.onChangeValueLocation} style={{paddingLeft:"1rem"}}>
                                    <div className="form-check d-flex align-items-center" >
                                        <input className="form-check-input"  type="radio" name="location"
                                               id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1" style={{marginLeft:".8rem"}}>
                                            Virtual
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio"  name="location"
                                               id="flexRadioDefault1" value="onsite" checked={this.state.locationtags === "onsite"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            On-site
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio" name="location"
                                               id="flexRadioDefault1" value="onsite and virtual" checked={this.state.locationtags === "onsite and virtual"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            Both
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <button onClick={this.handleFilterShow} className={"btn btn-save-filter"}>
                                Save Filter
                            </button>
                        </div>
                        <Header pageType={"Free"} />
                        <div className=" bg-white col-md-8 col-12 p-0">
                            <div className="search-section col-12 col-md-12 mt-4 mb-2 ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "100%", border:"none"}} placeholder="Search for conferences"/>

                                            </div>
                                        </form>
                                    </div>
                            </div>
                            <div className="saved-sources">
                                    <ResourcesNav />
                                <div className={"row d-flex align-items-center p-0"}>
                                    <div  className="col-md-8 col-6 d-flex align-items-center">
                                        <h1 style={{fontWeight:"600", fontSize:"15px", marginLeft:"20px"}} >Top UX Conferences to Attend in 2023</h1>
                                    </div>
                                    <div className="col-md-4 col-6 p-0 d-flex justify-content-end">
                                        <a className="mobfilter1 d-flex justify-content-end" style={{color:"#757D8A", marginRight:"1rem"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter </h6></a>

                                    </div>
                                </div>
                                    {
                                        this.state.loading ? <>
                                                {
                                                    [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                }

                                            </> :
                                            <table className="history-table mt-3 table-responsive" style={{overflow: "hidden"}}>
                                                <thead>
                                                <tr>
                                                    <th  style={{overflow: "hidden", zIndex:"1"}}>Conference name</th>
                                                    <th style={{overflow: "hidden"}}>Date</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    filtereditems.length > 0 ?
                                                        filtereditems.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)).map((item) => (
                                                            <tr className={new Date(item.start_date).toLocaleDateString() - new Date().toLocaleDateString() < "0" ? "passed" : ""} >
                                                                <td style={{overflow: "hidden"}}><a href={item.url} onClick={()=>this.handleClickCount(item.name)} target="_blank" style={{color:"#333333", textDecoration:"underline"}}>{item.name}</a> </td>
                                                                <td style={{overflow: "hidden"}}>
                                                                    {
                                                                        item.start_date===null ? "" :
                                                                            this.months[new Date(item.start_date).getMonth()] + " " + new Date(item.start_date).getDate()} - {this.months[new Date(item.end_date).getMonth()] + " " + new Date(item.end_date).getDate()}
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr>
                                                            <td colSpan="2" className="search-message"><img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in conferences, try our search
                                                                    engine instead</p>
                                                                <NotFound term={this.state.filter} />
                                                            </td>
                                                        </tr>
                                                }
                                                </tbody>
                                            </table>
                                    }

                            </div>

                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <Header pageType={"Free"} />
                    <div className="row mobile position-relative" style={{paddingLeft: "1rem", paddingRight:"1rem"}}>
                        <div ref={this.ref} className={this.state.showfilter? "row  p-0 d-flex justify-content-center position-absolute" : " d-none mobfilter1"} style={{zIndex:"1000", height:"80vh"}} >
                            <div className=" bg-white border-top px-4" >
                                <div className={"row mt-3"} style={{paddingLeft:".9rem", paddingRight:"2rem"}}>
                                    <div className={"col-4"}>
                                        <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                    </div>
                                    <div className={"col-4"}>
                                        <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                    </div>
                                    <div className={"col-4 d-flex justify-content-end"} style={{cursor:"pointer"}}>
                                        <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                    </div>
                                </div>
                                <h6 className={"filter-title1 text-center"} style={{marginTop:"1rem"}}>Sort by</h6>
                                <hr style={{marginLeft:"1rem", marginRight:"2rem"}}/>
                                <h6 className={"filter-title1"} style={{marginTop:"1rem", paddingLeft:"1rem"}}>Date</h6>
                                <div className="" onChange={this.onChangeValue} style={{paddingLeft:"1rem"}}>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio" name="cost"
                                               id="flexRadioDefault1"  value="free" checked={this.state.tags==="free"}  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            Free
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio" name="cost"
                                               id="flexRadioDefault1" value="paid" checked={this.state.tags==="paid"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            Paid
                                        </label>
                                    </div>
                                </div>
                                <hr style={{marginLeft:"1rem", marginRight:"2rem", marginTop:"1.2rem", height:".1rem"}}/>
                                <h6 className={"filter-title1"} style={{marginTop:"1rem", paddingLeft:"1rem"}}>Cost</h6>

                                <div className="" onChange={this.onChangeValueLocation} style={{paddingLeft:"1rem"}}>
                                    <div className="form-check d-flex align-items-center" >
                                        <input className="form-check-input"  type="radio" name="location"
                                               id="flexRadioDefault1" value="virtual" checked={this.state.locationtags === "virtual"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1" style={{marginLeft:".8rem"}}>
                                            Virtual
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio"  name="location"
                                               id="flexRadioDefault1" value="onsite" checked={this.state.locationtags === "onsite"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            On-site
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="radio" name="location"
                                               id="flexRadioDefault1" value="onsite and virtual" checked={this.state.locationtags === "onsite and virtual"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"  style={{marginLeft:".8rem"}}>
                                            Both
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <button onClick={this.handleFilterShow} className={"btn btn-save-filter"}>
                                Save Filter
                            </button>
                        </div>
                        <div className=" bg-white col-md-8 col-12 p-0">
                            <div className="search-section col-12 col-md-12 mb-2 ">
                                <div className="row d-flex align-items-center px-4 ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "100%", border:"none"}} placeholder="Search for conferences"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources">
                                <div className="row px-4">
                                    <ResourcesNav />
                                </div>
                                <div className={"row d-flex align-items-center px-4"} style={{marginTop:"24px"}}>
                                    <div  className="col-md-8 col-6 d-flex align-items-center">
                                        <h1 style={{fontSize:"20px", marginLeft:"0"}}>Top UX Conferences to Attend in 2023</h1>
                                    </div>
                                    <div className="col-md-4 col-6 p-0 d-flex justify-content-end">
                                        <a className="mobfilter1 d-flex justify-content-end" style={{color:"#757D8A"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter </h6></a>

                                    </div>
                                </div>
                                <div className={"row p-0"}>
                                    {
                                        this.state.loading ? <>
                                                {
                                                    [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                }

                                            </> :
                                            <table className="history-table mt-3 table-responsive" style={{overflow: "hidden"}}>
                                                <thead>
                                                <tr>
                                                    <th  style={{overflow: "hidden", zIndex:"1", paddingLeft:"2.2rem"}}>Conference name</th>
                                                    <th style={{overflow: "hidden", paddingLeft:"10%"}}>Date</th>
                                                </tr>
                                                </thead>

                                                <tbody className="mobmodal">
                                                {
                                                    filtereditems.length > 0 ?
                                                        filtereditems.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)).map((item) => (
                                                            <tr className={new Date(item.start_date).toLocaleDateString() - new Date().toLocaleDateString() < "0" ? "passed" : ""} >
                                                                <td style={{overflow: "hidden", paddingLeft:"2.2rem"}}><a href={item.url} onClick={()=>this.handleClickCount(item.name)} target="_blank" style={{color:"rgb(0, 148, 77)", textDecoration:"underline"}}>{item.name}</a> </td>
                                                                <td style={{overflow: "hidden", paddingLeft:"10%"}}>
                                                                    {
                                                                        item.start_date===null ? "" :
                                                                            this.months[new Date(item.start_date).getMonth()] + " " + new Date(item.start_date).getDate()} - {this.months[new Date(item.end_date).getMonth()] + " " + new Date(item.end_date).getDate()}
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr>
                                                            <td colSpan="5" className="search-message"><img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in conferences, try our search
                                                                    engine instead</p>
                                                                <NotFound term={this.state.filter} />
                                                            </td>
                                                        </tr>
                                                }
                                                </tbody>
                                            </table>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </MediaQuery>

                <Footer/>
            </>
        )
    }
}
export default Conferences