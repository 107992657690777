import React, {Component} from "react";
import {Link} from "react-router-dom";
import MediaQuery from "react-responsive";
import {FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";

class ResourcesNav extends Component{
    scrollRight = async ()=>{
        console.log("right")
        const right = document.querySelector(".notion-breadcrumbs");
        await right.scrollBy(200, 0);
    }
    scrollLeft= async ()=>{
        const left = document.querySelector(".notion-breadcrumbs");
        await left.scrollBy(-200, 0);
    }
    componentDidMount() {
        const section = document.querySelector( '.bread-active' );
        setTimeout(()=>{
            section.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        }, 1000)

    }

    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <div className={"container-fluid border mt-4 rounded-3"}>
                        <div className={"row  px-0"} style={{ marginTop:"1rem"}}>
                            <div className={"col-md-12 d-flex align-items-center"}>
                                <div  className=" notion-breadcrumbs "  >
                                    <Link className={window.location.pathname==="/uxr-communities" ? "bread-active" : ""}   ref={window.location.pathname==="/uxr-communities" ? (ref) => this.myRef=ref :""} to="/uxr-communities">
                                        <span > Communities </span>
                                    </Link>
                                    <Link className={window.location.pathname==="/uxr-conferences" ? "bread-active" : ""} ref={window.location.pathname==="/uxr-conferences" ? (ref) => this.myRef=ref :""} to="/uxr-conferences">
                                            <span >
                                                Conferences
                                            </span>

                                    </Link>
                                    <Link className={window.location.pathname==="/research-tools" ? "bread-active" : ""} ref={window.location.pathname==="/research-tools" ? (ref) => this.myRef=ref :""} to="/research-tools">
                                                <span   >
                                                    Tools
                                                </span>  </Link>
                                    <Link  className={window.location.pathname==="/research-vocabulary" ? "bread-active" : ""} ref={window.location.pathname==="/research-vocabulary" ? (ref) => this.myRef=ref :""} to="/research-vocabulary">
                                <span >
                                Vocabulary
                                </span>
                                    </Link>
                                    <Link className={window.location.pathname==="/volunteer-opportunities" ? "bread-active" : ""} ref={window.location.pathname==="/volunteer-opportunities" ? (ref) => this.myRef=ref :""} to="/volunteer-opportunities">
                                <span  >
                                Volunteer Opportunities
                                </span>
                                    </Link>
                                    <Link className={window.location.pathname==="/ux-podcasts" ? "bread-active" : ""} ref={window.location.pathname==="/ux-podcasts" ? (ref) => this.myRef=ref :""} to="/ux-podcasts">
                                <span >
                                Podcasts
                                </span>
                                    </Link>
                                    <Link className={window.location.pathname==="/research-books" ? "bread-active" : ""} ref={window.location.pathname==="/research-books" ? (ref) => this.myRef=ref :""} to="/research-books" >
                                <span  >
                                Books
                                </span>
                                    </Link>
                                    <Link className={window.location.pathname==="/uxr-courses" ? "bread-active" : ""} ref={window.location.pathname==="/uxr-courses" ? (ref) => this.myRef=ref :""} to="/uxr-courses">
                                <span >
                                Courses
                                </span>
                                    </Link>
                                    <Link className={window.location.pathname==="/uxr-methods" ? "bread-active" : ""} ref={window.location.pathname==="/uxr-methods" ? (ref) => this.myRef=ref :""} to="/uxr-methods">
                                <span   >
                                Methods
                                </span>
                                    </Link>
                                    <Link className={window.location.pathname==="/rb-originals" ? "bread-active" : ""}  ref={window.location.pathname==="/rb-originals" ? (ref) => this.myRef=ref :""} to="/rb-originals">
                                <span  >
                               Templates
                                </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className=" notion-breadcrumbs  col-12 col-md-12 d-flex align-items-center"  >
                        <Link to="/uxr-communities" style={{textDecoration: "none", fontSize:"16px"}}>
                            <span className={window.location.pathname==="/uxr-communities" ? "bread-active" : ""}> Communities </span>
                        </Link>
                        <Link to="/uxr-conferences" style={{ textDecoration: "none", fontSize:"16px"}}>
                                            <span className={window.location.pathname==="/uxr-conferences" ? "bread-active" : ""} >
                                                Conferences
                                            </span>

                        </Link>
                        <Link to="/research-tools" style={{textDecoration: "none", fontSize:"16px"}}>
                                                <span className={window.location.pathname==="/research-tools" ? "bread-active" : ""}  >
                                                    Tools
                                                </span>  </Link>
                        <Link to="/research-vocabulary" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-vocabulary" ? "bread-active" : ""} >
                                Vocabulary
                                </span>
                        </Link>
                        <Link to="/volunteer-opportunities" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/volunteer-opportunities" ? "bread-active" : ""} >
                                Volunteer Opportunities
                                </span>
                        </Link>
                        <Link to="/ux-podcasts" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/ux-podcasts" ? "bread-active" : ""}>
                                Podcasts
                                </span>
                        </Link>
                        <Link to="/research-books" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-books" ? "bread-active" : ""} >
                                Books
                                </span>
                        </Link>
                        <Link to="/uxr-courses" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-courses" ? "bread-active" : ""}>
                                Courses
                                </span>
                        </Link>
                        <Link to="/uxr-methods" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-methods" ? "bread-active" : ""}  >
                                Methods
                                </span>
                        </Link>
                        <Link to="/rb-originals" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/rb-originals" ? "bread-active" : ""}  >
                               Templates
                                </span>
                        </Link>
                    </div>
                </MediaQuery>
                <MediaQuery  maxWidth={1200} minWidth={461} >
                    <div className=" notion-breadcrumbs  col-12 col-md-12 d-flex align-items-center"  >
                        <Link to="/uxr-communities" style={{textDecoration: "none", fontSize:"16px"}}>
                            <span className={window.location.pathname==="/uxr-communities" ? "bread-active" : ""}> Communities </span>
                        </Link>
                        <Link to="/uxr-conferences" style={{ textDecoration: "none", fontSize:"16px"}}>
                                            <span className={window.location.pathname==="/uxr-conferences" ? "bread-active" : ""} >
                                                Conferences
                                            </span>

                        </Link>
                        <Link to="/research-tools" style={{textDecoration: "none", fontSize:"16px"}}>
                                                <span className={window.location.pathname==="/research-tools" ? "bread-active" : ""}  >
                                                    Tools
                                                </span>  </Link>
                        <Link to="/research-vocabulary" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-vocabulary" ? "bread-active" : ""} >
                                Vocabulary
                                </span>
                        </Link>
                        <Link to="/volunteer-opportunities" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/volunteer-opportunities" ? "bread-active" : ""} >
                                Volunteer Opportunities
                                </span>
                        </Link>
                        <Link to="/ux-podcasts" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/ux-podcasts" ? "bread-active" : ""}>
                                Podcasts
                                </span>
                        </Link>
                        <Link to="/research-books" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-books" ? "bread-active" : ""} >
                                Books
                                </span>
                        </Link>
                        <Link to="/uxr-courses" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-courses" ? "bread-active" : ""}>
                                Courses
                                </span>
                        </Link>
                        <Link to="/uxr-methods" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-methods" ? "bread-active" : ""}  >
                                Methods
                                </span>
                        </Link>
                    </div>

                </MediaQuery>


            </>
        );
    }
}
export default ResourcesNav