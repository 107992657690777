import React, {Component} from "react";
import Logo from "../../../assets/rb-beta logo.svg"
import usericon from "../../../assets/Group 89747.png"
import axios from "axios";
import logout from "../../../assets/Frame 44.png"
import Calendar from "../../../assets/Calendar.png"
import {Link} from "react-router-dom";
import LoginModal from "./../Modals/LoginModal";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Sidebar from "./Sidebar";
import {MdAccessTime, MdApps, MdClose, MdContentCopy, MdLogout, MdOpenInNew} from "react-icons/md";
import MediaQuery from "react-responsive";
import {Accordion, Modal, Dropdown} from "react-bootstrap";
import logoutimg from "../../../assets/logout.png"
import feedback from "../../../assets/feedback.png"
import eventsimg from "../../../assets/Group.png"
import webinarimg from "../../../assets/image 272.png"
import gem from "../../../assets/Gemstone Pink.H03.png"
import gem1 from "../../../assets/Gemstone Pink.H031.png"

export default class Header extends React.Component{
    state = {
        name: "",
        image: localStorage.getItem('image'),
        token: localStorage.getItem('token'),
        prof_img: "",
        clientID: "425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com",
        latitude:"",
        longitude:"",
        weatherA: "4c2d13a465447cb2736f797c1bba97ba",
        city:"",
        country:"",
        active:false,
        time: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
        stepsEnabled:true,
        initialStep:0,
        feedbacksuccess:false,
        loggedin:false,
        loginrun:false,
        events:[],
        time_min: new Date().toISOString(),
        iconDirection:"",
        side:"up",
        plantype:0, //By default, the plan type will be freemium.
        currentpage:window.location.pathname,
        displaycontent:false,
        number_of_days_left_for_trial_period:0,
        menus:[],
        today:new Date().toLocaleDateString('en-US',{year: 'numeric', month: 'short', day: 'numeric'}),
        searchpoints:"",
        refer:0,
        showpoints:false,
        referral:"",
        showTrialDaysLeft: false,
        trialDaysLeft: null

    }

   componentDidMount() {
        //Get the menus for the dropdown navigation menu
       axios.get(buildAPIUrl('v1/menus/')).then((res) => {
           this.setState({
               menus: res.data
           })
       }).catch((error) => {

       })
     //Get a list of events
       axios.get(buildAPIUrl('v1/events/?other_events=1')).then((res)=>{
         this.setState({
             events:res.data
         })

       }).catch((err)=>{

       })
       //Get user search points
       axios.get(buildAPIUrl('v1/users/me/'), {
           headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
           }
       }).then((res)=>{
            if(!res.data.rb_role){
                localStorage.setItem('profileupdate', "1")
            }
        this.setState({
            searchpoints:res.data.search_points,
            referral:res.data.referral_code
        })
       }).catch((error)=>{

       })
       const {pageType} = this.props
       if (pageType!=="Free"){
           this.checkuserpackage()
       }

        this.displayTrialDaysLeft()
   }
   checkuserpackage = async ()=>{
        const userpackage = await axios.get(buildAPIUrl('v1/users/me'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (userpackage.data.is_trial_period_over || userpackage.data.is_trial_period_over===null) {
            if(userpackage.data.is_staff || userpackage.data.is_superuser || userpackage.data.is_student){
                return 
            }else{
              //check if user has an active subscription
              const userSubscription = await axios.get(buildAPIUrl('v1/subscriptions?is_rb_pro_plan=True'), {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`
                  }
              })

              let blackListedPages = ["/skillshop-2023", "/skillshop-2022"]
              //check if subscription information exists
              
              if(userSubscription.data.length > 0) {
                    const lastSub = 0
                  if ( !userSubscription.data[lastSub].db_is_active){

                      if (window.location.pathname !== "/" &&  !blackListedPages.includes(window.location.pathname)){
                        if(window.location.pathname !== "/user-settings"){
                          if (window.location.pathname!=="/webinar"){
                              if (window.location.pathname!=="/payment-plans"){
                                  if (window.location.pathname!=="/new-user-payment"){
                                     /* window.location.assign("/")*/
                                  }
                              }
                          }
                        }

                      }
                  }
              } else {
                  if (window.location.pathname !== "/" && !blackListedPages.includes(window.location.pathname)){
                      if (window.location.pathname!=="/webinar"){
                        if(window.location.pathname !== "/user-settings"){
                          if (window.location.pathname!=="/payment-plans"){
                              if (window.location.pathname!=="/new-user-payment"){
                                /*  window.location.assign("/")*/
                              }
                          }
                        }
                      }

                  }
              }
          }

        }

    }
    handleFeedbackModal=()=>{
        this.setState({
            feedback:true,
            show:false
        })
    }
    onSuccess = (res) => {
        localStorage.clear()
        window.location.href='/login'
    }
    onLogout = (res) => {
        localStorage.removeItem('email')
        localStorage.removeItem('image')
        localStorage.removeItem('latitude')
        localStorage.removeItem('longitude')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('profileupdate')
        window.location.href='/'
    }
    handleModalOpen = (result)=>{
        this.setState({
            show: true,
            siteid:result
        })
    }
    handleModalClose = ()=>{
        this.setState({
            show: false
        })
    }
    openSidebar = () => {
        this.setState({
            active:true
        })
    }
    handlesidebaropenprops = () => {
        this.props.openSidebar()
    }
    handlesidebarcloseprops = () => {
        this.props.closeSidebar()
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleOpen = ()=>{
        if (this.state.active) {
            this.setState({
                active: false
            })
        } else {
            this.setState({
                active: true
            })
        }
    }
    handleClose = () => {
        this.setState({
            active: false
        })
    }
    handleFeedback = async (e) =>{
        e.preventDefault()
        const response  = await axios.post(buildAPIUrl('v1/feed_backs/'), {
            feed_back: this.state.feedbackfield,
        }, {headers: {Authorization: `Bearer ${this.state.token}`}}).then((res) => {
            this.setState({
                feedbacksuccess: true
            })
            setTimeout(()=>{
                this.onLogout()
            }, 3000)
        })
    }

    changeAccordionIcon = (id)=>{
        this.setState({
            iconDirection:id
        })
        if(this.state.side==="up"){
            this.setState({
                side:"down"
            })
        } else {
            this.setState({
                side:"up"
            })
        }

    }
    showCalendar = () => {
        if(this.state.displaycontent){
            this.setState({
                displaycontent:false
            })
        } else {
            this.setState({
                displaycontent:true
            })
        }
    }
    handleFeedbackClose = () =>{
        this.setState({
            feedback:false
        })
    }
    handleReferAction = (id, url) => {
        this.setState({
            refer:id
        })
        if (id===2){
            navigator.clipboard.writeText(url)
        }
    }
    handleShowPoints= async ()=>{
        if (this.state.searchpoints===true){
          await  this.setState({
                showpoints:false,
                refer:0,
            })
        } else {
           await this.setState({
                showpoints:true,
                refer:0,
            })
        }
    }
    handleShowPointsClose=()=>{
        this.setState({
            showpoints:false,
            refer:0,
        })
    }
    displayTrialDaysLeft= async ()=>{
      
        const userpackage = await axios.get(buildAPIUrl('v1/users/me'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        
        
          if(userpackage.data.is_trial_period_over===false){
            const startfree = await axios.get(buildAPIUrl('v1/users/trial_period/?is_community_group=0'), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            this.setState({showTrialDaysLeft: true})
    
            
            if(startfree.data.number_of_days_left_for_trial_period > 0){
                this.setState({
                    trialDaysLeft: startfree.data.number_of_days_left_for_trial_period
                })
            }else{
                this.setState({
                    trialDaysLeft: 0
                })
            }
            
            
        }



}

    render() {

        return (
            <>
                <Modal centered show={this.state.feedback}  onHide={this.handleFeedbackClose}>
                    <Modal.Body >
                        <div className="row logout-modal" style={{borderRadius:"8px"}}>
                            <div className="container d-flex justify-content-center">
                                <div className={this.state.feedbacksuccess? "alert alert-success":  "d-none"} >
                                    Feedback sent successfully. Logging you out...
                                </div>
                            </div>

                            <img src={Logo} style={{width:"100px"}}/>
                            <div className="container text-center d-flex justify-content-center align-items-center">
                                <div className="container-fluid">
                                    <img className="logout-icon" style={{height:"80px", width:"80px"}} src={feedback}/>
                                    <h6>
                                        We would love your feedback
                                    </h6>
                                    <form  >
                                        <div className="mb-2 d-flex justify-content-center">
                                               <textarea className="form-control feedback-textarea" name="feedbackfield" onChange={this.handleInput} value={this.state.feedbackfield}>

                                               </textarea>
                                        </div>

                                        <button className="btn cancel-feedback" onClick={this.onLogout}>Just Log Me Out</button>
                                        <button className="btn send-feedback" onClick={this.handleFeedback}>Send Feedback</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <MediaQuery minWidth={1100}>

                    {
                        this.state.token ?
                            <Modal centered show={this.state.show}  onHide={this.handleModalClose}>
                                <Modal.Body >
                                    <div className="row logout-modal" style={{borderRadius:"8px"}}>
                                        <img src={Logo} style={{width:"100px"}}/>
                                        <div className="container text-center d-flex justify-content-center align-items-center">
                                            <div>
                                                <img className="logout-icon" style={{height:"80px", width:"80px"}} src={logoutimg}/>
                                                <h6>
                                                    On no! You’re leaving.....
                                                </h6>
                                                <h6>
                                                    Are you sure?
                                                </h6>
                                                <button className="btn kidding" onClick={this.handleModalClose}>Naah, just kidding</button>
                                                <button className="btn yeslog" onClick={this.handleFeedbackModal}>Yes, Log me out</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>

                            </Modal> :    <LoginModal device={"desktop"} openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                    }



                           {
                               this.props.type==="notloggedin" ? <>
                               <header className="p-0" style={{ marginTop:"0"}}>
                                   <div className="container-fluid cont-header p-0 m-0">
                                       <nav className="navbar navbar-expand-lg navbar-light bg-white w-100 p-0">
                                           <div className={"row p-0 w-100"}>
                                               <div className={"col-md-6"} style={{background:"#F6F4FF", padding:"1.438rem 4rem"}}>
                                                   <Link className={this.props.type==="mobile"  ? "mobile-nav navbar-brand" : this.props.type==="tablet"  ? "mobile-nav navbar-brand":  "navbar-brand"} style={{ textDecoration:"none"}} to="/">
                                                       <img alt={"logo"} src={Logo} width={"100"} height={"100"} />
                                                   </Link>
                                               </div>
                                               <div className={"col-md-6"} style={{paddingTop:"1.438rem", paddingRight:"3rem"}}>
                                                   {
                                                       this.state.token ? <button className="navbar-toggler" type="button" onClick={this.handleOpen} aria-label="Toggle navigation" style={{marginRight:".6rem"}}>
                                                           <span className="navbar-toggler-icon"></span>
                                                       </button> :   <p onClick={()=>this.handleModalOpen()} className="nav-link logbtndesk"><button className="btn btn-login">Login</button> </p>
                                                   }
                                                   <div className={this.props.type==="mobile" ? "d-none" :this.props.type==="tablet" ? "d-none" : "collapse navbar-collapse d-flex justify-content-end align-items-center"} id="navbarSupportedContent">
                                                       <ul className="navbar-nav mr-auto">
                                                           <li className="nav-item d-flex align-items-center">
                                                           <a href="" style={{width:"30px"}}></a>
                                                       </li>

                                                       
                                                           <li className="nav-item d-flex align-items-center ">

                                                               <Link className="nav-link"  to="/webinar" style={{ fontSize:"16px"}}>Sign up for April Skillshop</Link>
                                                           </li>
                                                           { this.state.showTrialDaysLeft ?
                                                                <li className="nav-item d-flex align-items-center ">
                                                                    <Link  to="/payment-plans#pay" >
                                                                        <button type="button" class="btn btn-warning" style={{marginRight: "20px"}}>
                                                                            {this.state.trialDaysLeft} days remaining
                                                                        </button>
                                                                    </Link>
                                                                </li>
                                                            :<></>
                                                            }

                                                           <li className="nav-item d-flex align-items-center ">
                                                                <Link className="nav-link" to="/features" style={{color:"black", fontSize:"16px"}}> Features</Link>
                                                            </li>
                                                            {
                                                                this.state.token ? <></>
                                                                :   <li className="nav-item d-flex align-items-center ">
                                                                <Link className="nav-link" to="/pricing" style={{color:"black", fontSize:"16px"}}> Pricing</Link>
                                                            </li>
                                                            }

                                                           <li className="logb nav-item ">
                                                               <Link to={"/join-rb"} onClick={()=>this.handleModalOpen()} style={{margin:"0"}} className={this.props.device==="mobile"?"nav-link hidden-mob logbtn" : "nav-link logbtn" }><button className="btn btn-login">Login</button>
                                                                   {/*   <img src={animatedgif} className={localStorage.getItem('firstvisit')==="0" ? "d-none" : ""} data-tip data-for="logintooltip" style={{width:"50px", height:"50px"}}/>*/}
                                                               </Link>

                                                           </li>

                                                       </ul>
                                                   </div>
                                               </div>
                                           </div>





                                       </nav>
                                   </div>
                               </header>
                               </> : <>
                               <header className="" style={{ marginTop:"0"}}>
                                   <div className="container-fluid cont-header p-0 m-0">
                                       <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top border-bottom" style={{padding:".6rem 4rem"}}>
                                           <Link className={this.props.type==="mobile"  ? "mobile-nav navbar-brand" : this.props.type==="tablet"  ? "mobile-nav navbar-brand":  "navbar-brand"} style={{ textDecoration:"none"}} to="/">
                                               <img alt={"logo"} src={Logo} width={"100"} height={"100"} />
                                           </Link>
                                           {
                                               this.state.token ? <button className="navbar-toggler" type="button" onClick={this.handleOpen} aria-label="Toggle navigation" style={{marginRight:".6rem"}}>
                                                   <span className="navbar-toggler-icon"></span>
                                               </button> :   <p onClick={()=>this.handleModalOpen()} className="nav-link logbtndesk"><button className="btn btn-login">Login</button> </p>
                                           }


                                           <div className={this.props.type==="mobile" ? "d-none" :this.props.type==="tablet" ? "d-none" : "collapse navbar-collapse d-flex justify-content-end align-items-center"} id="navbarSupportedContent">
                                               <ul className="navbar-nav mr-auto">
                                                   <li className="nav-item d-flex align-items-center">
                                                   <a href="" style={{width:"30px"}}></a>
                                               </li>

                                               { this.state.showTrialDaysLeft ?
                                                        <li className="nav-item d-flex align-items-center ">

                                                                    <Link  to="/payment-plans#pay" >
                                                                        <button type="button" class="btn btn-warning" style={{marginRight: "20px"}}>
                                                                            {this.state.trialDaysLeft} days remaining
                                                                        </button>
                                                                    </Link>
                                                        </li>
                                                    :<></>
                                                    }

                                                   <li className="nav-item d-flex align-items-center ">
                                                       <img src={webinarimg} style={{display:"inline-block"}}/>
                                                       <Link className="nav-link"  to="/webinar" style={{color:"#00944D", fontSize:"16px"}}>Register for our upcoming webinar</Link>
                                                   </li>
                                                     <li className="nav-item d-flex align-items-center ">
                                                    <Link className="nav-link" to="/features" style={{color:"black", fontSize:"16px"}}> Features</Link>
                                                    </li>
                                                    {
                                                                this.state.token ? <></>
                                                                :   <li className="nav-item d-flex align-items-center ">
                                                                <Link className="nav-link" to="/pricing" style={{color:"black", fontSize:"16px"}}> Pricing</Link>
                                                            </li>
                                                    }
                                                   {
                                                       localStorage.getItem('token') ? <>
                                                           <Dropdown className="d-flex align-items-center" autoClose={false} >
                                                               <Dropdown.Toggle className={"bg-white border-0 d-flex justify-content-center"} id="dropdown-autoclose-false">
                                                                   <img alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} />
                                                               </Dropdown.Toggle>

                                                               <Dropdown.Menu style={{marginTop:"0", width:"25rem"}} drop={"down-centered"}>
                                                                   <div className={"container-fluid"} style={{padding:"2rem 1.5rem 0 1.5rem", }} >
                                                                       <div className={"row"}>
                                                                           <div className={"col-md-6"}>
                                                                               <p style={{color:"#00944D", fontSize:"14px", fontWeight:"500", lineHeight:"15px"}}>
                                                                                   Ux Events
                                                                               </p>
                                                                           </div>
                                                                           <div className={"col-md-6 d-flex justify-content-end"}>

                                                                               <Link to={"/events"}>
                                                                                   <MdOpenInNew style={{color:"#0D004C", fontSize:"18px"}} />
                                                                               </Link>


                                                                           </div>
                                                                       </div>

                                                                   </div>

                                                                   <div className="container-fluid" style={{padding:"0rem 1.5rem 1.5rem 1.5rem"}}>
                                                                       <>
                                                                           {
                                                                               this.state.events.filter((eve)=>{return eve.date===this.state.today}).length > 0 ?
                                                                                   <>
                                                                                       {
                                                                                           this.state.events.filter((eve)=>{return eve.date===this.state.today}).map((event)=>(
                                                                                               <div className={"container-fluid p-0"} style={{marginTop:".875rem"}}>
                                                                                                   <div className={"row h-100"}>
                                                                                                       <div className={"col-md-4 "}>
                                                                                                           <div className={""}>
                                                                                                               <img  src={event.thumbnail} style={{width:"100%", borderRadius:"5px"}}/>
                                                                                                           </div>

                                                                                                       </div>
                                                                                                       <div className={"col-md-8"}>
                                                                                                           <h5 style={{fontSize:"16px", lineHeight:"124.52%", fontWeight:"500"}}>
                                                                                                               {event.name}
                                                                                                           </h5>
                                                                                                           <div className={"row "}>
                                                                                                               <div className={"col-md-12 d-flex align-items-center "}>
                                                                                                                   <div>
                                                                                                                       <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#00944D", fontWeight:"400", fontSize:"12px"}}>{event.date}</span>
                                                                                                                       <MdAccessTime style={{fontSize:"16px", marginLeft:"0.313rem"}} /> <span style={{fontWeight:"400", fontSize:"12px"}}>{event.time_zone}</span>
                                                                                                                   </div>

                                                                                                               </div>
                                                                                                               <div className={"col-md-6 "}>

                                                                                                               </div>
                                                                                                           </div>
                                                                                                           <div className={"container-fluid p-0"}>
                                                                                         <span style={{textAlign:"left", fontSize:"12px", fontWeight:"500", color:"#522FFF"}}>
                                                                                            <Link to={"events"}>View Details</Link>
                                                                                         </span>
                                                                                                           </div>
                                                                                                       </div>
                                                                                                   </div>
                                                                                               </div>
                                                                                           ))
                                                                                       }
                                                                                   </> :
                                                                                   <>
                                                                                       <div className={"container d-flex justify-content-center"}>
                                                                                           <div>
                                                                                               <img src={eventsimg}/>
                                                                                               <p style={{marginTop:"2rem", fontSize:"16px", fontWeight:"400"}}>
                                                                                                   There are no events today
                                                                                               </p>
                                                                                               <Link to={"/events"} className={"btn"} style={{background:"#070707", color:"white", padding:"0.75rem 1.25rem", marginTop:"1.438rem"}}>
                                                                                                   View upcoming events
                                                                                               </Link>
                                                                                           </div>
                                                                                       </div>
                                                                                   </>
                                                                           }




                                                                       </>



                                                                   </div>
                                                               </Dropdown.Menu>
                                                           </Dropdown>
                                                       </> : <>
                                                       </>
                                                   }


                                                   {/*
                                        <li className="nav-item d-flex align-items-center ">
                                            <Link className="nav-link" to={"/pricing"} style={{color:"black", fontSize:"14px"}}>Pricing</Link>
                                        </li>*/}



                                                   {
                                                       this.state.token?<>

                                                               <div className="userprof">
                                                                   <li className="nav-item d-flex align-items-center dropdown ">
                                                                       <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                                                          role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                                           {/*<img src={usericon} className="user-icon"/>*/}
                                                                           <MdApps className="loggedintooltip" style={{fontSize:"30px"}} />
                                                                       </a>
                                                                       <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                           <div className="container  nav-menu-items">
                                                                               <div className="row" style={{paddingBottom:"1.25rem", marginTop:"1rem", overflowY:"scroll", overflowX:"hidden"}}>

                                                                                   {
                                                                                       this.state.menus.length > 0 ? <>
                                                                                           {
                                                                                               this.state.menus.map((menu)=>(
                                                                                                   <>
                                                                                                       <div className={menu.url===window.location.pathname ?  "desk-menu-item-active d-flex align-items-center" : "desk-menu-item  d-flex align-items-center"}>
                                                                                                           <Link to={menu.url} style={{textDecoration:"none"}}>
                                                                        <span className="d-flex align-items-center " >
                                                                           {
                                                                               <img src={menu.inactive_icon}/>
                                                                           }
                                                                            <h6 className={"me-0 ms-3"}>{menu.name}</h6>
                                                                           </span>


                                                                                                           </Link>
                                                                                                       </div>
                                                                                                   </>
                                                                                               ))
                                                                                           }
                                                                                       </> : <>

                                                                                       </>
                                                                                   }

                                                                                       <div className={" desk-menu-item d-flex align-items-center"}>
                                                                                           <Link onClick={this.handleModalOpen} style={{textDecoration:"none"}}>
                                                                                           <div  style={{cursor:"pointer"}}>
                                                                                    <span className="d-flex align-items-center" >
                                                                                       <MdLogout />
                                                                                    <h6 className={"me-0 ms-3"}>Logout</h6>
                                                                                    </span>

                                                                                           </div>
                                                                                           </Link>
                                                                                       </div>

                                                                               </div>
                                                                           </div>
                                                                       </ul>
                                                                   </li>
                                                               </div>


                                                           </>:
                                                           <>

                                                               <li className="logb nav-item ">
                                                                   <Link to={"/join-rb"} onClick={()=>this.handleModalOpen()} style={{margin:"0"}} className={this.props.device==="mobile"?"nav-link hidden-mob logbtn" : "nav-link logbtn" }><button className="btn btn-login">Login</button>
                                                                       {/*   <img src={animatedgif} className={localStorage.getItem('firstvisit')==="0" ? "d-none" : ""} data-tip data-for="logintooltip" style={{width:"50px", height:"50px"}}/>*/}
                                                                   </Link>

                                                               </li>
                                                           </>

                                                   }

                                               </ul>
                                           </div>
                                       </nav>
                                   </div>
                               </header>
                               </>
                           }
                </MediaQuery>
                <MediaQuery minWidth={461} maxWidth={1100}>
                    {
                        this.state.token ?
                            <Modal centered show={this.state.show}  onHide={this.handleModalClose}>
                                <Modal.Body >
                                    <div className="row logout-modal" style={{borderRadius:"8px"}}>
                                        <img src={Logo} style={{width:"100px"}}/>
                                        <div className="container text-center d-flex justify-content-center align-items-center">
                                            <div>
                                                <img className="logout-icon" style={{height:"80px", width:"80px"}} src={logoutimg}/>
                                                <h6>
                                                    On no! You’re leaving.....
                                                </h6>
                                                <h6>
                                                    Are you sure?
                                                </h6>
                                                <button className="btn kidding" onClick={this.handleModalClose}>Naah, just kidding</button>
                                                <button className="btn yeslog" onClick={this.handleFeedbackModal}>Yes, Log me out</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>

                            </Modal> :    <LoginModal device={"desktop"} openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                    }
                    <header className="tablet" style={{marginBottom:"0"}}>
                        {
                            this.state.token ?  "" :    <LoginModal openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                        }
                        <Sidebar className="d-none" closeSideBar={this.handleClose} openSideBar={this.handleOpen} status={this.state.active} />
                        <div className="container-fluid cont-header p-0 m-0">
                            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                                <Link className={this.props.type==="mobile"  ? "mobile-nav navbar-brand" : this.props.type==="tablet"  ? "mobile-nav navbar-brand":  "navbar-brand"} style={{ textDecoration:"none"}} to="/">
                                    <img alt={"logo"} src={Logo} width={"100"} height={"100"}/>
                                </Link>
                                {
                                    this.state.token ?
                                        <div className={"d-flex justify-content-end"}>
                                            {/* <Dropdown className="d-inline" autoClose={false} >
                                                <Dropdown.Toggle className={"bg-white border-0 d-flex justify-content-center"} id="dropdown-autoclose-false">
                                                    <img alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu style={{marginTop:"0", width:"20rem", marginLeft:"1rem"}} drop={"down-centered"}>
                                                    <div className={"container-fluid"} style={{padding:"2rem 2.688rem 0 2.688rem", }} >
                                                        <div className={"row"}>
                                                            <div className={"col-6"}>
                                                                <p style={{color:"#00944D", fontSize:"14px", fontWeight:"500", lineHeight:"15px"}}>
                                                                    Ux Events
                                                                </p>
                                                            </div>
                                                            <div className={"col-6 d-flex justify-content-end"}>

                                                               <Link to={"/events"}> <MdOpenInNew style={{color:"#0D004C", fontSize:"18px"}} /></Link>


                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="container-fluid" style={{padding:"1rem"}}>
                                                        <>
                                                            {
                                                                this.state.events.filter((eve)=>{return eve.date===this.state.today}).length > 0 ?
                                                                    <>
                                                                        {
                                                                            this.state.events.filter((eve)=>{return eve.date===this.state.today}).map((event)=>(
                                                                                <div className={"container-fluid"} style={{marginTop:".875rem"}}>
                                                                                    <div className={"row h-100"}>
                                                                                        <div className={"col-"}>
                                                                                            <div className={""}>
                                                                                                <img  src={event.thumbnail} style={{width:"100%", borderRadius:"5px"}}/>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className={"col-md-8 mt-2"}>
                                                                                            <h5 style={{fontSize:"16px", lineHeight:"124.52%", fontWeight:"500"}}>
                                                                                                {event.name}
                                                                                            </h5>
                                                                                            <div className={"row "}>
                                                                                                <div className={"col-6 d-flex align-items-center  "}>
                                                                                                    <div>
                                                                                                        <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#00944D", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className={"col-6"}>
                                                                                                    <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={"container-fluid p-0"} style={{marginTop:"0.813rem"}}>
                                                                                         <span style={{textAlign:"left", fontSize:"12px", fontWeight:"500", color:"#522FFF"}}>
                                                                                            <Link to={"events"}>View Details</Link>
                                                                                         </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </> :
                                                                    <>
                                                                        <div className={"container d-flex justify-content-center"}>
                                                                            <div>
                                                                                <img src={eventsimg}/>
                                                                                <p style={{marginTop:"2rem", fontSize:"16px", fontWeight:"400"}}>
                                                                                    There are no events today
                                                                                </p>
                                                                                <Link to={"/events"} className={"btn"} style={{background:"#070707", color:"white", padding:"0.75rem 1.25rem", marginTop:"1.438rem"}}>
                                                                                    View upcoming events
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }




                                                        </>



                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>*/}
                                            <button className="navbar-toggler" type="button" onClick={this.handleOpen} aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                        </div>
                                        :   <Link to={"/join-rb"} className="nav-link"><button className="btn btn-login">Login</button> </Link>
                                }

                            </nav>
                        </div>
                    </header>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    {
                        this.state.token ?
                            <Modal centered show={this.state.show}  onHide={this.handleModalClose}>
                                <Modal.Body >
                                    <div className="row logout-modal" style={{borderRadius:"8px"}}>
                                        <img src={Logo} style={{width:"100px"}}/>
                                        <div className="container text-center d-flex justify-content-center align-items-center">
                                            <div>
                                                <img className="logout-icon" style={{height:"80px", width:"80px"}} src={logoutimg}/>
                                                <h6>
                                                    On no! You’re leaving.....
                                                </h6>
                                                <h6>
                                                    Are you sure?
                                                </h6>
                                                <button className="btn kidding" onClick={this.handleModalClose}>Naah, just kidding</button>
                                                <button className="btn yeslog" onClick={this.handleFeedbackModal}>Yes, Log me out</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>

                            </Modal> :    <LoginModal device={"desktop"} openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                    }
                    <header className="mobile" style={{marginBottom:"0"}}>
                        {
                            this.state.token ?  "" :    <LoginModal openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                        }
                        <Sidebar className="d-none" closeSideBar={this.handleClose} openSideBar={this.handleOpen} status={this.state.active} />
                        <div className="container-fluid cont-header p-0 m-0">
                            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                                <Link className={this.props.type==="mobile"  ? "mobile-nav navbar-brand" : this.props.type==="tablet"  ? "mobile-nav navbar-brand":  "navbar-brand"} style={{ textDecoration:"none"}} to="/">
                                    <img alt={"logo"} src={Logo} width={"100"} height={"100"}/>
                                </Link>
                                {
                                    this.state.token ?
                                        <div className={"d-flex justify-content-end"}>
                                           {/* <Dropdown className="d-inline" autoClose={false} >
                                                <Dropdown.Toggle className={"bg-white border-0 d-flex justify-content-center"} id="dropdown-autoclose-false">
                                                    <img alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu style={{marginTop:"0", width:"20rem", marginLeft:"1rem"}} drop={"down-centered"}>
                                                    <div className={"container-fluid"} style={{padding:"2rem 2.688rem 0 2.688rem", }} >
                                                        <div className={"row"}>
                                                            <div className={"col-6"}>
                                                                <p style={{color:"#00944D", fontSize:"14px", fontWeight:"500", lineHeight:"15px"}}>
                                                                    Ux Events
                                                                </p>
                                                            </div>
                                                            <div className={"col-6 d-flex justify-content-end"}>

                                                               <Link to={"/events"}> <MdOpenInNew style={{color:"#0D004C", fontSize:"18px"}} /></Link>


                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="container-fluid" style={{padding:"1rem"}}>
                                                        <>
                                                            {
                                                                this.state.events.filter((eve)=>{return eve.date===this.state.today}).length > 0 ?
                                                                    <>
                                                                        {
                                                                            this.state.events.filter((eve)=>{return eve.date===this.state.today}).map((event)=>(
                                                                                <div className={"container-fluid"} style={{marginTop:".875rem"}}>
                                                                                    <div className={"row h-100"}>
                                                                                        <div className={"col-"}>
                                                                                            <div className={""}>
                                                                                                <img  src={event.thumbnail} style={{width:"100%", borderRadius:"5px"}}/>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className={"col-md-8 mt-2"}>
                                                                                            <h5 style={{fontSize:"16px", lineHeight:"124.52%", fontWeight:"500"}}>
                                                                                                {event.name}
                                                                                            </h5>
                                                                                            <div className={"row "}>
                                                                                                <div className={"col-6 d-flex align-items-center  "}>
                                                                                                    <div>
                                                                                                        <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#00944D", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className={"col-6"}>
                                                                                                    <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={"container-fluid p-0"} style={{marginTop:"0.813rem"}}>
                                                                                         <span style={{textAlign:"left", fontSize:"12px", fontWeight:"500", color:"#522FFF"}}>
                                                                                            <Link to={"events"}>View Details</Link>
                                                                                         </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </> :
                                                                    <>
                                                                        <div className={"container d-flex justify-content-center"}>
                                                                            <div>
                                                                                <img src={eventsimg}/>
                                                                                <p style={{marginTop:"2rem", fontSize:"16px", fontWeight:"400"}}>
                                                                                    There are no events today
                                                                                </p>
                                                                                <Link to={"/events"} className={"btn"} style={{background:"#070707", color:"white", padding:"0.75rem 1.25rem", marginTop:"1.438rem"}}>
                                                                                    View upcoming events
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }




                                                        </>



                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>*/}
                                            <button className="navbar-toggler" type="button" onClick={this.handleOpen} aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                        </div>
                                        :   <Link to={"/join-rb"} className="nav-link"><button className="btn btn-login">Login</button> </Link>
                                }

                            </nav>
                        </div>
                    </header>
                </MediaQuery>
            </>
            )


    }
}